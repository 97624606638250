<template>
  <div class="background-img-4 fx-center flx-d-column h-100">
    <div class="title text fx-center flx-d-column">
      <h2 class="step">6º Passo</h2>
      <h2 class>EM QUAIS FORMATOS VOCÊ PRECISA?</h2>
      <p>SELECIONE OS FORMATOS QUE DESEJA ADAPTAR SEU VÍDEO. POR Ex: 1:1 PARA Face, 9:16 PARA IGTV</p>
    </div>
    <!-- <div class="text fx-center flx-d-column">
      <img src="../../assets/img/1-1.png" alt="upload" width="40%" />
      <p> IDEAL PARA: feed E Face </p>
    </div>
    <div class="text fx-center flx-d-column">
      <img src="../../assets/img/4-5.png" alt="upload" width="40%" />
      <p> IDEAL PARA: feed E Face </p>
    </div>
    <div class="text fx-center flx-d-column">
      <img src="../../assets/img/16-9.png" alt="upload" width="40%" />
      <p> IDEAL PARA: Youtube E Face </p>
    </div>-->
    <div
      class="text fx-center flx-d-column"
      :class="nuggetFormat == '9:16' ? 'selected': ''"
      @click="chooseFormat('9:16')"
    >
      <img src="../../assets/img/9-16.png" alt="upload" width="40%" />
      <p>IDEAL PARA: IGTV, Stories E Tiktok</p>
    </div>
    <div class="fx-center">
      <button class="mg-2-em btn-yellow" type="button" @click="sendTemplate">Próximo Passo</button>
    </div>
    <div class="mancha-step">
      <img src="../../assets/img/element-2.png" alt="imagem" />
    </div>
  </div>
</template>

<style>
.flx-d-column {
  border: 3px solid transparent;
}
.flx-d-column.selected {
  border-color: red;
}
</style>

<script>
import router from "../../router";
import store from "../../store";

export default {
  name: "ExportNugget",
  data() {
    return {
      nuggetFormat: "9:16",
    };
  },
  created() {
    if (store.state.audioNugget && store.state.audioNugget.nuggetFormat) {
      // Step is already set, skip it
      router.push({
        name: "DownloadNugget",
      });
      return;
    }
  },
  methods: {
    chooseFormat(format) {
      this.nuggetFormat = format;
    },
    async sendTemplate() {
      store.commit("mergeAudioNugget", {
        nuggetFormat: this.nuggetFormat,
      });
      router.push({
        name: "DownloadNugget",
      });
    },
  },
};
</script>
